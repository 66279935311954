"use client"

import { useState, useEffect } from "react"
import Link from "next/link"
import { Button } from "@/components/ui/button"
import { X } from "lucide-react"
import { motion, AnimatePresence } from "framer-motion"

interface BottomBannerProps {
  message: string
  linkText: string
  link: string
}

export function BottomBanner({ message, linkText, link }: BottomBannerProps) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500)
    return () => clearTimeout(timer)
  }, [])

  const onClose = () => setIsVisible(false)

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-0 left-0 right-0 bg-background border-t border-border p-2 sm:p-4 shadow-lg"
        >
          <div className="container mx-auto flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 relative">
            <p className="text-foreground text-xs sm:text-sm pr-8 sm:pr-0">
              {message}{" "}
              <Link
                href={link}
                className="hover:underline text-blue-400 hover:text-blue-400/80"
              >
                {linkText}
              </Link>
            </p>
            <Button
              variant="ghost"
              size="sm"
              onClick={onClose}
              aria-label="バナーを閉じる"
              className="absolute top-0 right-0 sm:relative sm:top-auto sm:right-auto"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
